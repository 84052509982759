import React from 'react';
import './App.css';

import Resume from './components/Resume'


function App() {
  return (
    <Resume />
  );
}

export default App;
